<template>
  <div class="content-list" v-if="isReady">
    <Action-panel :isReady="isReady">
      <div>
      </div>
    </Action-panel>
    <v-card class="ma-6">
      <v-tabs v-model="selectedTab" flat color="primary">
        <v-tab
          v-for="tab in platformLanguages"
          :key="tab.id"
          :href="`#tab-${tab.id}`"
          :vertical="true"
          centered
          icons-and-text
        >
          {{ tab.description }}
        </v-tab>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="tab in platformLanguages"
            :key="tab.id"
            :value="`tab-${tab.id}`"
          >
            <v-card :isReady="isReady">
              <TableList
                :is-ready="model.length > 0"
                :headers="headers"
                :items="getItems(tab)"
                :items-per-page="20"
                :footer-props="footerProps"
                item-key="id"
                :filters-visible="true"
                :filters="filters"
                :current-filters.sync="currentFilters"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import ActionPanel from "@/components/panel/ActionPanel";
import TableList from "@/components/table/TableList";
import { Utility } from "@/lib/Utility";

@Component({
  components: {
    ConfirmDialog,
    ActionPanel,
    TableList
  },
  inject: {
    translationService: "translationService",
    languageService: "languageService",
    toastService: "toastService"
  }
})
export default class TranslationEdit extends Vue {
  isReady = false;
  formId = "edit-translations-form";
  platformLanguages = [];
  contexts = [];
  selectedTab = null;
  currentFilters = null;
  footerProps = {
    itemsPerPageOptions: [
      5, 10, 15, 20, -1
    ]
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get filters() {
    return [
      {
        text: this.translations.labels.translation_table_context,
        description: this.translations.labels.translation_table_context,
        itemsLabel: this.translations.labels.translation_table_context,
        items: this.contexts.map(c => { return { text: c, value: c }; }),
        propToFilter: "context",
      }
    ];
  }

  get headers() {
    return [
      {
        text: this.translations.labels.translation_table_context,
        value: "context",
        sortable: true,
        width: "10%"
      },
      {
        text: this.translations.labels.translation_table_code,
        value: "code",
        sortable: true,
        width: "15%"
      },
      {
        text: this.translations.labels.translation_table_label,
        value: "label",
        type: "textarea",
        sortable: false,
        onChange: this.updateTranslation,
        width: "75%"
      },
      /* note: decomment this when we have free creation of translation *\/
      {
        text: this.translations.labels.table_delete,
        value: "delete",
        sortable: false,
        type: "action",
        icon: "mdi-delete",
        onAction: (item) => {
          this.deleteTranslation(item);
        },
        hide: (_action, item) => {
          return !!item.isSystem;
        }
      },
      /**/
    ];
  }

  updateTranslations(defaultLanguageId, targetContext, targetCode, newLabel) {
    for (const i in this.model) {
      if (this.model.idLanguage == defaultLanguageId) {
        continue;
      }
      for (const j in this.model[i].items) {
        const item = this.model[i].items[j];
        if (item.context !== targetContext || item.code !== targetCode) {
          continue;
        }
        this.model[i].items[j].hint = `Default: ${newLabel}`;
      }
    }
  }

  async deleteTranslation(item) {
    try {
      await this.translationService.delete(item.idLanguage, item.context, item.code, item.label);
      this.toastService.success(this.translations.success.translation_delete);
    } catch (e) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async updateTranslation(item) {
    item.icon = null;
    item.error = null;
    item.success = null;

    item.icon = "mdi-content-save";
    try {
      await this.translationService.createOrUpdate(item.idLanguage, item.context, item.code, item.label);
      item.icon = "mdi-check";
      item.success = true;
      if (item.isDefault) {
        this.updateTranslations(item.idLanguage, item.context, item.code, item.label);
      }
      setTimeout(() => {
        item.icon = null;
        item.success = null;
      }, 3000);
    } catch (e) {
      item.icon = "mdi-close";
      item.error = e.description;
    }
  }

  getItems(tab) {
    const target = this.model.filter((l) => l.idLanguage === tab.id);
    if (target.length == 0) {
      return [];
    }
    return target[0].items;
  }

  getFormTitle () {
    return this.translations.pageTitles.platformConfigurations_edit;
  }

  async created () {
    this.platformLanguages = await this.languageService.list();
    const translations = await this.translationService.list();

    // TODO: retrieve default language from column
    const defaultLanguageId = this.platformLanguages.filter(x => x.isDefault == true)[0].id;

    this.contexts = [];

    const defaultLanguage = translations.filter(l => l.idLanguage === defaultLanguageId);
    const rows = [];
    if (defaultLanguage.length > 0) {
      for (const context in defaultLanguage[0].translations) {
        this.contexts.push(context);
        for (const code in defaultLanguage[0].translations[context]) {
          rows.push({
            id: `${context}-${code}`,
            idLanguage: null,
            context: context,
            code: code,
            icon: null,
            isDefault: false,
            isSystem: true,
            hint: `Default: ${defaultLanguage[0].translations[context][code]}`,
            label: null
          });
        }
      }
    }

    this.model = [];

    for (const i in this.platformLanguages) {
      const currLang = this.platformLanguages[i];
      const targetLanguage = translations.filter(l => { return l.idLanguage === currLang.id; });
      let newTransl = {
        idLanguage: currLang.id,
        items: []
      };
      newTransl.items = Utility.clone(rows);
      for (const j in newTransl.items) {
        newTransl.items[j].idLanguage = newTransl.idLanguage;
      }

      if (targetLanguage.length > 0) {
        const transls = targetLanguage[0].translations;
        for (const j in newTransl.items) {
          const { context, code, isSystem } = newTransl.items[j];
          newTransl.items[j].label = typeof transls[context] !== "undefined" && typeof transls[context][code] !== "undefined"
            ? transls[context][code]
            : null;
          newTransl.items[j].isSystem = isSystem;
          if (currLang.id == defaultLanguageId) {
            newTransl.items[j].hint = null;
            newTransl.items[j].isDefault = true;
          }
        }
      }

      this.model.push(newTransl);
    }

    this.isReady = true;
  }
}
</script>
